.lect_notes_main_cont{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lect_notes_main_cont_inner{
    padding: 0 30px;
    min-width: 45%;
    display: block;
    text-align: left;
}

@media (min-width: 1440px) {
    .lect_notes_main_cont_inner {
        min-width: 0;
        width: 650px;  /* Fix width at 650px for very large screens */
    }
}
@media (max-width: 1347px) {
.lect_notes_main_cont_inner{
    min-width: 60%;
}
}
@media (max-width: 1060px) {
.lect_notes_main_cont_inner{
    min-width: 80%;
}
}

@media (max-width: 800px) {
.lect_notes_main_cont_inner{
    min-width: 95%;
}
}

/*  */
.lect_notes_page-title {
    margin-top: 20px;
    font-size: 36px;
    text-align: center;
    color: #333;
  }

 .lect_notes_page-sub_title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
    color: #5d5c5c;
  }
  
  /* Section Styling */
  .section {
    margin-bottom: 40px;
  }
  
  .section-title {
    font-size: 28px;
    margin-bottom: 20px;
    color: #555;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  /* Subsection Styling */
  .subsection {
    margin-bottom: 30px;
  }
  
  .subsection-title {
    font-size: 22px;
    color: #666;
    margin-bottom: 15px;
  }
  
  /* Notes Grid */
  .notes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid */
    gap: 20px;
  }
  
  /* Note Card Styling */
  .note-card {
    background-color: #fdfdfd;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .note-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .note-content h4 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .note-link {
    font-size: 14px;
    color: #4682B4;
    text-decoration: none;
    font-weight: bold;
  }
  
  .note-link:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .page-title {
      font-size: 28px;
    }
  
    .section-title {
      font-size: 24px;
    }
  
    .subsection-title {
      font-size: 20px;
    }
  }

/* TOC */
.lect_notes_content-container {
    width: 100%;
    display: block;
  }
  
  /* Sections Container */
  .lect_notes_sections {
    width: 100%;
    display: block;
  }
.table-of-contents {
    margin-bottom: 40px;
    padding: 20px;
    background: linear-gradient(135deg, #f8f9fa, #ffffff); /* Subtle gradient */
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
  }
  
  .table-of-contents h3 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
  }
  
  .table-of-contents ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .table-of-contents li {
    margin-bottom: 10px;
  }
  
  .table-of-contents button {
    font-size: 16px;
    font-weight: 500;
    color: #4682B4;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.2s ease;
    padding: 8px 12px;
    border-radius: 8px;
  }
  
  .table-of-contents button:hover {
    color: #ffffff; /* White text on hover */
    background-color: #4682B4; /* Blue background */
    transform: translateX(5px); /* Slight movement to the right */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Hover shadow */
  }
  
  .table-of-contents li:last-child button {
    margin-bottom: 0; /* Remove margin from last item */
  }
  
  /* Sticky ToC for Large Screens */
