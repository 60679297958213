.aboutme-bg {
    background: linear-gradient(135deg, #F5F5F5, #ffffff);
    padding: 20px 0;
    }
    .first-text-about-me{
        margin-top: 20px;
    }
    
    .aboutme-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    }
    
    .aboutme-header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    }
    
    .aboutme-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }
    
    .aboutme-title {
    font-family: ‘Roboto’, sans-serif;
    font-weight: bold;
    color: #333;
    }
    
    .aboutme-section {
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    }
    
    .aboutme-section-title {
    font-size: 24px;
    color: #4682B4;
    margin-bottom: 15px;
    }
    
    .aboutme-text {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 10px;
    }
    
    /* New Projects Section Styles */
    .projects-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    }
    
    .project-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 8px;
    transition: box-shadow 0.3s ease;
    }
    
    .project-item:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
    
    .project-info {
    font-size: 16px;
    color: #555;
    }
    
    .project-link { text-decoration: none; background-color: #4682B4; color: #fff; padding: 8px 12px; border-radius: 6px; font-size: 14px; transition: background-color 0.3s ease; display: inline-block; min-width: 130px; text-align: center; }

    /* Mobile responsiveness adjustments */ 
    @media (max-width: 480px) { .project-item { flex-direction: column; align-items: stretch; } .project-info { margin-bottom: 10px; text-align: center; } .project-link { min-width: 100%; } }
    
    .project-link:hover {
    background-color: #2a5d8f;
    }
    
    /* Responsive adjustments */
    @media (max-width: 768px) {
    .aboutme-content {
    padding: 20px;
    }
    .aboutme-title {
    font-size: 28px;
    }
    .aboutme-section-title {
    font-size: 22px;
    }
    .aboutme-text, .project-info {
    font-size: 14px;
    }
    }