.header_main_cont{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_main_cont_inner{
    min-width: 45%;
    display: block;
}

@media (min-width: 1440px) {
    .header_main_cont_inner {
        min-width: 0;
        width: 650px;  /* Fix width at 650px for very large screens */
    }
}
@media (max-width: 1347px) {
.header_main_cont_inner{
    min-width: 60%;
}
}
@media (max-width: 1060px) {
.header_main_cont_inner{
    min-width: 80%;
}
}

@media (max-width: 800px) {
.header_main_cont_inner{
    min-width: 95%;
}
}