* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Ensures padding and border are included in element's width/height */
}
body{

    background-color: #F5F5F5;
}
.main_cont{
    width: 100vw;
    height: 100vh;
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_cont_inner{
    min-width: 45%;
    min-height: 100px;
    display: block;
}
@media (min-width: 1440px) {
    .main_cont_inner {
        min-width: 0;
        width: 650px;  /* Fix width at 650px for very large screens */
    }
}
@media (max-width: 1347px) {
.main_cont_inner{
    min-width: 60%;
}
}
@media (max-width: 1060px) {
.main_cont_inner{
    min-width: 80%;
}
}

@media (max-width: 800px) {
.main_cont_inner{
    min-width: 95%;
}
}



.main_cont_about{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.main_cont_about_inner{
    text-align: right;
    width: 49%;
    height: 100%;
}
.main_cont_text_about_me{
    height: 200px;
    display: flex;
    flex-direction: column; /* Stacks child elements vertically */
    justify-content: space-between;
}

.main_cont_about img{
    width: 200px;
    height: 200px;
    border-radius: 50%;

}

.main_cont_about_inner h1{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 36px;
    margin: 0;
    padding: 0;

}
.main_cont_about_inner h2{
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    font-size: 24px;
    color: #7D7D7D;

}

.main_cont_about_inner_icons{
    gap: 0;
    display: flex;
    justify-content: right;
}

.main_cont_about_inner a{
    margin: 0;
    padding: 0;
    color: black;
    font-size: 40px;
    height: 40px;
    margin-left: 20px;
    gap: 0;
}
.main_cont_about_inner a i{
    margin: 0;
    padding: 0;
    text-decoration: none;
    line-height: 1;
    display: inline-block;
    gap: 0;
    transition: 0.3s all;
}
.main_cont_about_inner a i:hover{
    transform: scale(1.2);
    color: #4682B4;
}
.main_cont_about_inner a i::before{
    margin: 0;
    padding: 0;
    vertical-align: middle;
}


.main_cont_routs{
    width: 100%;
    background-color: #DBDBDB;
    margin-top: 20px;
    padding: 12px 30px;
    display: flex;
    justify-content: space-between;
    border-radius: 21px;
}

.main_cont_routs .main_header_a{
    position: relative;
    text-decoration: none;
    color: black;
    transition: 0.3s;
    padding: 0;
    line-height: 1;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.main_cont_routs .main_header_a:hover{
    cursor: pointer;
}
.main_cont_routs .main_header_a::after{
    content: '';
    position: absolute;
    bottom: -5px; /* Adjust based on spacing */
    left: 0;
    width: 0;
    height: 2px;
    background-color: black;
    transition: width 0.3s ease;
}

.main_cont_routs .main_header_a:hover::after{
    width: 100%;
}

.main_cont_routs .main_cont_routs_active::after{
    content: '';
    position: absolute;
    bottom: -5px; /* Adjust based on spacing */
    left: 0;
    width: 0;
    height: 2px;
    background-color: black;
    transition: width 0.3s ease;
    width: 100%;
}

@media (max-width: 650px) {
    .main_cont {
        padding: 10px; /* Add some padding */
    }

    /* Make inner container stack vertically */
    .main_cont_inner {
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        align-items: center;   /* Center all elements horizontally */
    }

    /* Profile section (image and text) */
    .main_cont_about {
        display: flex;
        flex-direction: column; /* Stack image and text vertically */
        align-items: center;    /* Center-align image and text */
        gap: 20px;              /* Add spacing between image and text */
        text-align: center;     /* Center-align text content */
        width: calc(100% - 30px);
    }

    /* Image adjustments */
    .main_cont_about img {
        width: 150px; /* Reduce image size for smaller screens */
        height: 150px;
    }

    /* Text about section adjustments */
    .main_cont_text_about_me {
        width: 100%; /* Let text take full width */
        height: auto;
        /* margin: 0; Reset margin */
    }
    .main_cont_about_inner_icons{
        margin-top: 10px;
        justify-content: space-between;
        width: 100%;
    }
    .main_cont_about_inner_icons a{
        margin: 0;
    }

    .main_cont_about_inner h1 {
        text-align: center;
        margin-top: 10px;
        font-size: 24px; /* Adjust font size for smaller screens */
    }
    
    .main_cont_about_inner h2 {
        text-align: center;
        margin-top: 10px;
        font-size: 16px; /* Adjust subheader size */
    }

    /* Navigation bar adjustments */
    .main_cont_routs {
        flex-direction:row; /* Stack navigation links vertically */
        align-items: center; /* Center links */
        padding: 15px; /* Add padding for better spacing */
        transition: all 0.5s ease-in-out;
    }

    .main_cont_routs .main_header_a {
        margin: 5px 0; /* Add space between each link */
        font-size: 14px; /* Reduce font size for links */
    }
    #main_go_to_main{
        display: none;
    }
}